// RequestListPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Import your CSS file

const RequestListPage = () => {
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    // Sayfa yüklendiğinde GET isteği yapılır
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.amanosdoner.com/amanosnotesYtk9vXsV-list-amanos-doner');
        setRequestList(response.data);
      } catch (error) {
        console.error('Veri alınamadı', error);
      }
    };

    fetchData();
  }, []); // Boş bağımlılık dizisi sayfa yüklendiğinde bir kere çalışmasını sağlar

  // createdDate'yi düzgün bir formata dönüştüren fonksiyon
  const formatDate = (timestamp) => {
    const date = new Date(timestamp[0], timestamp[1] - 1, timestamp[2], timestamp[3], timestamp[4], timestamp[5], timestamp[6] / 1000000);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="health-page-container">
      <h1 className="title">Amanos Döner İş Maddelerimiz</h1>
      <br /><br /><br />
      <Link to="/">
        <button className="back-to-home-button">Ana Sayfa</button>
      </Link>
      <br />
      <table>
        <thead>
          <tr>
          <th>No</th>
            <th>Şube</th>
            <th>İsim</th>
            <th>Konu</th>
            <th>Detay</th>
            <th>Açıklama</th>
            <th>Oluşturma Tarihi</th>
          </tr>
        </thead>
        <tbody>
          {requestList.map((request) => (
            <tr key={request.id}>
              <td>{request.id}</td>
              <td>{request.branch}</td>
              <td>{request.name}</td>
              <td>{request.subject}</td>
              <td>{request.detail}</td>
              <td>{request.description}</td>
              <td>{formatDate(request.createdDate)}</td>
             
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestListPage;
