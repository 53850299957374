// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import RequestPage from './RequestRecord';
import GetResult from './GetResult';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Use `Routes` to group multiple `Route` elements */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/RequestRecord" element={<RequestPage />} />
          <Route path="/GetResult" element={<GetResult />} />


        </Routes>
      </div>
    </Router>
  );
}

export default App;
